import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as FileSaver from 'file-saver';
import { FieldInterface } from '../../../shared/dynamic-form-builder/interfaces/field.interface';
import { CalculatorsService } from '../../services/calculators.service';
import { CalculatorInterface } from '../calculators-list/interfaces/calculator.interface';
import { CategoryInterface } from '../categories-list/interfaces/category.interface';
import { CalculatorFormInterface, CalculatorResultInterface } from './interfaces/calculator-result.interface';
import { CalculatorStepInterface } from './interfaces/calculator-step.interface';
import { CalculatorStepsInfoInterface } from './interfaces/calculator-steps-info.interface';
import { CalculatorFormDataInterface } from './interfaces/calculator.interface';

const REFUND_LUNG_CANCER_CALCULATOR_ID = '588df3ce-1111-2222-9402-a221dbf44d36';
const LUMP_SUM_FROM_HOSPITAL_PERSPECTIVE_CALCULATOR_ID = '588df3ce-1111-3333-9402-a221dbf44d36';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss'],
})
export class CalculatorComponent implements OnInit {
  public calculatorResult: CalculatorResultInterface;
  public calculatorFormData: CalculatorFormInterface;
  public calculatorId: string;
  public categoryId: string;
  public showForm = true;
  public showResult = false;
  public calculatorDetails: CalculatorInterface;
  public categoryDetails: CategoryInterface;
  public stepIndex = 0;
  public hasNext = false;
  public fields: FieldInterface[];
  public loading = true;
  public isRefundLungCancerCustomCalculator = false;
  public isLumpSumFromHospitalPerspectiveCustomCalculator = false;
  private valuesHistory: CalculatorFormInterface[] = [];
  private aggregatedValues: CalculatorFormInterface = {};

  constructor(private route: ActivatedRoute, private service: CalculatorsService) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(async params => {
      this.calculatorId = params['calculatorId'];
      this.categoryId = params['categoryId'];
      if (this.calculatorId === REFUND_LUNG_CANCER_CALCULATOR_ID) {
        this.isRefundLungCancerCustomCalculator = true;
      }
      if (this.calculatorId === LUMP_SUM_FROM_HOSPITAL_PERSPECTIVE_CALCULATOR_ID) {
        this.isLumpSumFromHospitalPerspectiveCustomCalculator = true;
      }
      this.calculatorDetails = await this.service.getCalculatorDetails(this.calculatorId);
      this.loading = false;
      if (this.calculatorDetails.stepCount && this.calculatorDetails.stepCount > 1) {
        this.service.getCalculatorStepsInfo(this.calculatorId, this.stepIndex).then((info: CalculatorStepsInfoInterface) => {
          this.fields = info.inputs;
          this.hasNext = info.hasNext;
        });
      }
      this.categoryDetails = await this.service.getCategoryDetails(this.categoryId);
    });
  }

  public runCalculator(table: CalculatorResultInterface): void {
    this.calculatorResult = table;
    this.showForm = false;
    this.showResult = true;
  }

  public takeCalculatorFormData(formData: CalculatorFormDataInterface): void {
    this.calculatorFormData = formData.values;
  }

  public disableResultVisibility(disable: boolean): void {
    if (disable) {
      this.showResult = false;
      this.showForm = true;
    }
  }

  public exportData(event: boolean): void {
    if (event && this.calculatorFormData && this.calculatorId) {
      this.service.exportCalculator(this.calculatorId, this.calculatorFormData).subscribe((res: Blob) => {
        const fileName = 'calc-result.xlsx';
        FileSaver.saveAs(res, fileName);
      });
    }
  }

  public onHandlePreviousStep(handle: boolean): void {
    if (handle) {
      this.stepIndex--;
      this.service.getCalculatorStepsInfo(this.calculatorId, this.stepIndex).then((info: CalculatorStepsInfoInterface) => {
        const values = this.valuesHistory[this.stepIndex];
        info.inputs.forEach(input => {
          input.value = values[input.name];
        });
        this.fields = info.inputs;
        this.hasNext = info.hasNext;
      });
    }
  }

  public onHandleWizardData(event: CalculatorFormDataInterface): void {
    this.aggregatedValues = { ...this.aggregatedValues, ...event.values };
    this.calculatorFormData = this.aggregatedValues;
    this.valuesHistory[this.stepIndex] = event.values;
    this.service.sendStep(this.calculatorId, this.stepIndex, event.values).then((res: CalculatorStepInterface) => {
      this.hasNext = res.next && res.next.hasNext;
      if (event.hasNext) {
        this.fields = res.next.inputs;
        this.stepIndex++;
      } else {
        this.service.runCalculator(this.calculatorId, this.aggregatedValues).then(calculatorResult => {
          this.runCalculator(calculatorResult);
        });
      }
    });
  }
}
