import { Component } from '@angular/core';

@Component({
  selector: 'no-data-label',
  template: `
    <div>
      <ng-content></ng-content>
    </div>
  `,
  styles: [
    `
      div {
        text-align: center;
        color: #43425D;
        margin: 4em;
      }
    `,
  ],
})
export class NoDataLabelComponent {}
