import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldInterface } from '../interfaces/field.interface';

@Component({
  selector: 'number',
  template: `
    <div [formGroup]="form">
      <input [attr.type]="field.type" class="form-control" [id]="field.name"
             [name]="field.name" [formControlName]="field.name">
    </div>
  `,
})
export class NumberComponent {
  @Input() field: FieldInterface;
  @Input() form: FormGroup;
}
