import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CalculatorFormDataInterface } from '../../views/components/calculator/interfaces/calculator.interface';
import { FieldInterface } from './interfaces/field.interface';

@Component({
  selector: 'dynamic-form-builder',
  template: `
    <form *ngIf="form" [formGroup]="form" class="form-horizontal">
      <div *ngFor="let field of fields">
        <field-builder [field]="field" [form]="form"></field-builder>
      </div>
      <div class="form-group row">
        <div class="col-md-12" style="display: flex; justify-content: flex-end;">
          <button *ngIf="stepIndex > 0"
                  type="button" (click)="previousStep()" [disabled]="!form.valid" class="btn btn-secondary mr-2">
            {{ 'calculator.buttons.back' | translate }}
          </button>
          <button *ngIf="!hasNext"
                  type="button" (click)="submitForm()" [disabled]="!form.valid" class="btn btn-primary">
            {{ 'calculator.buttons.run' | translate }}
          </button>
          <button *ngIf="hasNext"
                  type="button" (click)="nextStep()" [disabled]="!form.valid" class="btn btn-primary">
            {{ 'calculator.buttons.next' | translate }}
          </button>
        </div>
      </div>
    </form>
  `,
})
export class DynamicFormBuilderComponent implements OnInit, OnChanges {
  @Input() fields: FieldInterface[] = [];
  @Input() hasNext = false;
  @Input() stepIndex = 0;
  @Output() onPreviousStep: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onSubmit: EventEmitter<CalculatorFormDataInterface> = new EventEmitter<CalculatorFormDataInterface>();

  public form: FormGroup;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['fields']) {
      this.prepareForm();
    }
  }

  ngOnInit(): void {
    this.prepareForm();
  }

  public submitForm(): void {
    this.onSubmit.emit({ hasNext: false, values: this.form.value });
  }

  public nextStep(): void {
    this.onSubmit.emit({ hasNext: true, values: this.form.value });
  }

  public previousStep(): void {
    this.onPreviousStep.emit(true);
  }

  private getFieldValidators(field: FieldInterface): any[] {
    const result = [];
    if (field.required) {
      result.push(Validators.required);
    }
    if (field.minValue !== null) {
      result.push(Validators.min(field.minValue));
    }
    if (field.maxValue !== null) {
      result.push(Validators.max(field.maxValue));
    }
    return result;
  }

  private prepareForm(): void {
    const fieldsControls = {};
    for (const field of this.fields) {
      if (field.type === 'text') {
        fieldsControls[field.name] = field.required
          ? new FormControl(field.value || '', Validators.required)
          : new FormControl(field.value || '');
      } else if (field.type === 'number') {
        fieldsControls[field.name] = new FormControl(field.value || field.minValue || 0, this.getFieldValidators(field));
      } else if (field.type === 'dropdown') {
        fieldsControls[field.name] = field.required
          ? new FormControl(field.value || '', Validators.required)
          : new FormControl(field.value || '');
      } else {
        const opts = {};
        for (const opt of field.options) {
          opts[opt.key] = new FormControl(opt.value);
        }
        fieldsControls[field.name] = new FormGroup(opts);
      }
    }
    this.form = new FormGroup(fieldsControls);
  }
}
