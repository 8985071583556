import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CalculatorsService } from '../../services/calculators.service';
import { RecentCalculatorModel } from './models/recent-calculator.model';

@Component({
  selector: 'calculators-recent',
  templateUrl: './recent-calculators-list.component.html',
  styleUrls: ['./recent-calculators-list.component.scss'],
})
export class RecentCalculatorsListComponent implements OnInit {
  public calculators: RecentCalculatorModel[];
  public calculatorsLoading = true;

  constructor(private readonly service: CalculatorsService,
              private readonly router: Router) {
  }

  async ngOnInit(): Promise<void> {
    await this.loadCalculators();
  }

  public onSelectCalculator(categoryId: string, calculatorId: string): void {
    this.router.navigate(['home', 'categories', categoryId, 'calculators', calculatorId]);
  }

  private async loadCalculators(): Promise<void> {
    this.calculators = await this.service.getRecentCalculators();
    this.calculatorsLoading = false;
  }
}
