import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CalculatorsService } from '../../services/calculators.service';
import { CategoryInterface } from '../categories-list/interfaces/category.interface';
import { CalculatorModel } from './models/calculator.model';

@Component({
  selector: 'calculators-list',
  templateUrl: './calculators-list.component.html',
  styleUrls: ['./calculators-list.component.scss'],
})
export class CalculatorsListComponent implements OnInit {
  public calculators: CalculatorModel[];
  public categoryDetails: CategoryInterface;
  public calculatorsLoading = true;
  private categoryId: string;
  constructor(
    private readonly route: ActivatedRoute,
    private readonly service: CalculatorsService,
    private readonly router: Router,
  ) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(async params => {
      this.categoryId = params['categoryId'];
      this.categoryDetails = await this.service.getCategoryDetails(this.categoryId);
      await this.loadCalculators(this.categoryId);
    });
  }

  public onSelectCalculator(calculatorId: string): void {
    this.router.navigate([calculatorId], { relativeTo: this.route });
  }

  public markAsFavorite(calculatorId: string): void {
    this.service.markAsFavorite(calculatorId).then(async () => {
      await this.loadCalculators(this.categoryId);
    });
  }

  public unMarkFavorite(calculatorId: string): void {
    this.service.unMarkFavorite(calculatorId).then(async () => {
      await this.loadCalculators(this.categoryId);
    });
  }

  private async loadCalculators(categoryId: string): Promise<void> {
    this.calculatorsLoading = true;
    this.calculators = await this.service.getCalculatorsList(categoryId);
    this.calculatorsLoading = false;
  }

}
