import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FieldInterface } from '../../../../shared/dynamic-form-builder/interfaces/field.interface';
import { CalculatorsService } from '../../../services/calculators.service';
import { CalculatorResultInterface } from '../interfaces/calculator-result.interface';
import { CalculatorFormDataInterface } from '../interfaces/calculator.interface';

@Component({
  selector: 'calculator-form',
  templateUrl: './calculator-form.component.html',
  styleUrls: ['./calculator-form.component.scss'],
})
export class CalculatorFormComponent implements OnInit {
  @Input() calculatorId: string;
  @Input() calculatorName: string;
  @Output() onCalculatorFormResult: EventEmitter<CalculatorFormDataInterface> = new EventEmitter<CalculatorFormDataInterface>();
  @Output() onSubmit: EventEmitter<CalculatorResultInterface> = new EventEmitter<CalculatorResultInterface>();

  public fields: FieldInterface[];

  constructor(private readonly service: CalculatorsService) {
  }

  ngOnInit(): void {
    this.loadCalculator(this.calculatorId);
  }

  public submitForm(event: CalculatorFormDataInterface): void {
    this.onCalculatorFormResult.emit(event);
    this.service.runCalculator(this.calculatorId, event.values).then(calculatorResult => {
      this.onSubmit.emit(calculatorResult);
    });
  }

  private loadCalculator(calculatorId: string): void {
    this.service.getCalculator(calculatorId).then(fields => {
      this.fields = fields.inputs;
    });
  }

}
