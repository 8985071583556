import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap';
import { DynamicFormBuilderComponent } from './dynamic-form-builder.component';
import { FieldBuilderComponent } from './field-builder/field-builder.component';
import { CheckBoxComponent } from './fields/checkbox';
import { DropDownComponent } from './fields/dropdown';
import { NumberComponent } from './fields/number';
import { RadioComponent } from './fields/radio';
import { TextBoxComponent } from './fields/textbox';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TooltipModule,
    TranslateModule,
  ],
  declarations: [
    DynamicFormBuilderComponent,
    FieldBuilderComponent,
    TextBoxComponent,
    DropDownComponent,
    CheckBoxComponent,
    RadioComponent,
    NumberComponent,
  ],
  exports: [DynamicFormBuilderComponent],
  providers: [],
})
export class DynamicFormBuilderModule {
}
