import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Guid } from '../../shared/types/types';
import { CalculatorFormDefinitionInterface } from '../components/calculator/calculator-form/interfaces/calculator-form-definition.interface';
import {
  CalculatorFormInterface, CalculatorResultInterface,
} from '../components/calculator/interfaces/calculator-result.interface';
import { CalculatorStepInterface } from '../components/calculator/interfaces/calculator-step.interface';
import { CalculatorStepsInfoInterface } from '../components/calculator/interfaces/calculator-steps-info.interface';
import {
  LumpSumFromHospitalPerspectiveBodyInterface, LumpSumFromHospitalPerspectiveResponseInterface,
  RefundLungCancerCalculatorBodyInterface,
  RefundLungCancerCalculatorDefaultsInterface, RefundLungCancerCalculatorResponseInterface,
} from '../components/calculator/interfaces/custom-calculators.interface';
import { CalculatorInterface } from '../components/calculators-list/interfaces/calculator.interface';
import { CalculatorModel } from '../components/calculators-list/models/calculator.model';
import { RecentCalculatorInterface } from '../components/calculators-recent/interfaces/recent-calculator.interface';
import { RecentCalculatorModel } from '../components/calculators-recent/models/recent-calculator.model';
import { FavoriteCalculatorInterface } from '../components/calculcators-favorites/interfaces/favorite-calculator.interface';
import { FavoriteCalculatorModel } from '../components/calculcators-favorites/models/favorite-calculator.model';
import { CategoryInterface } from '../components/categories-list/interfaces/category.interface';
import { CategoryModel } from '../components/categories-list/models/category.model';
import { ChangePasswordInterface } from '../components/change-password-modal/interfaces/change-password.interface';

@Injectable()
export class CalculatorsService {

  constructor(private readonly httpClient: HttpClient) {
  }

  public getCalculatorsList(category: string): Promise<CalculatorModel[]> {
    return this.httpClient.get<CalculatorInterface[]>(`/api/list/calculators/${category}`)
      .pipe(
        map(calculators => calculators.map(json => new CalculatorModel(json))),
      ).toPromise();
  }

  public getCategories(): Promise<CategoryModel[]> {
    return this.httpClient.get<CategoryInterface[]>('/api/list/categories')
      .pipe(
        map(categories => categories.map(json => new CategoryModel(json))),
      ).toPromise();
  }

  public getRecentCalculators(): Promise<RecentCalculatorModel[]> {
    return this.httpClient.get<RecentCalculatorInterface[]>('/api/list/calculators/recent')
      .pipe(
        map(calculators => calculators.map(json => new RecentCalculatorModel(json))),
      ).toPromise();
  }

  public getFavoritesCalculators(): Promise<RecentCalculatorModel[]> {
    return this.httpClient.get<FavoriteCalculatorInterface[]>('/api/list/calculators/favorites')
      .pipe(
        map(calculators => calculators.map(json => new FavoriteCalculatorModel(json))),
      ).toPromise();
  }

  public getCalculator(id: Guid): Promise<CalculatorFormDefinitionInterface> {
    return this.httpClient.get<CalculatorFormDefinitionInterface>(`/api/describe/detailed/${id}`).toPromise();
  }

  public runCalculator(id: Guid, body: CalculatorFormInterface): Promise<CalculatorResultInterface> {
    return this.httpClient.post<CalculatorResultInterface>(`/api/run/${id}`, body).toPromise();
  }

  public exportCalculator(id: Guid, body: CalculatorFormInterface): Observable<Blob> {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return this.httpClient.post<Blob>(`/api/save/xlsx/${id}`, body, httpOptions);
  }

  public getCalculatorDetails(calculatorId: Guid): Promise<CalculatorInterface> {
    return this.httpClient.get<CalculatorInterface>(`/api/describe/${calculatorId}`).toPromise();
  }

  public getCategoryDetails(categoryId: Guid): Promise<CategoryInterface> {
    return this.httpClient.get<CalculatorInterface>(`/api/describe/category/${categoryId}`).toPromise();
  }

  public getCalculatorStepsInfo(calculatorId: Guid, stepIndex: number): Promise<CalculatorStepsInfoInterface> {
    return this.httpClient.get<CalculatorStepsInfoInterface>(`/api/describe/step/${calculatorId}/${stepIndex}`).toPromise();
  }

  public sendStep(id: Guid, stepIndex: number, body: CalculatorFormInterface): Promise<CalculatorStepInterface> {
    return this.httpClient.post<CalculatorStepInterface>(`/api/step/${id}/${stepIndex}`, body).toPromise();
  }

  public markAsFavorite(calculatorId: Guid): Promise<void> {
    return this.httpClient.post<void>(`/api/favorites/calculators/${calculatorId}`, {}).toPromise();
  }

  public unMarkFavorite(calculatorId: Guid): Promise<void> {
    return this.httpClient.delete<void>(`/api/favorites/calculators/${calculatorId}`).toPromise();
  }

  public changePassword(body: ChangePasswordInterface): Promise<void> {
    return this.httpClient.post<void>(`/api/users/change/myPassword`, body).toPromise();
  }

  public getSpecimens(calculatorId: Guid): Promise<string[]> {
    return this.httpClient.get<string[]>(`/api/custom/${calculatorId}/specimens`).toPromise();
  }

  public getDefaults(calculatorId: Guid, specimen: string): Promise<RefundLungCancerCalculatorDefaultsInterface> {
    return this.httpClient.get<RefundLungCancerCalculatorDefaultsInterface>(`/api/custom/${calculatorId}/defaults/${specimen}`).toPromise();
  }

  public runLumpSumFromHospitalPerspectiveCalculator(body: LumpSumFromHospitalPerspectiveBodyInterface):
    Promise<LumpSumFromHospitalPerspectiveResponseInterface> {
    return this.httpClient
      .post<LumpSumFromHospitalPerspectiveResponseInterface>(`/api/custom/588df3ce-1111-3333-9402-a221dbf44d36/run`, body).toPromise();
  }

  public runRefundLungCancerCalculator(body: RefundLungCancerCalculatorBodyInterface):
    Promise<RefundLungCancerCalculatorResponseInterface> {
    return this.httpClient
      .post<RefundLungCancerCalculatorResponseInterface>(`/api/custom/588df3ce-1111-2222-9402-a221dbf44d36/run`, body).toPromise();
  }
}
