import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaderResponse,
  HttpParams,
  HttpResponse,
  HttpResponseBase
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {UserAuthInterface} from '../auth/auth.interface';
import {AuthService} from '../auth/auth.service';
import {ToastService} from '../shared/toast/toast.service';
import { ContactInterface } from './components/contact-modal/interfaces/contact.interface';

@Injectable()
export class LoginService {

  constructor(
    private readonly httpClient: HttpClient,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly toastService: ToastService,
  ) {
  }

  public signIn(name: string, password: string): void {
    this.login(name, password).then(user => {
      this.authService.user = user;
      this.authService.token = user.token;
      this.toastService.showSuccess('Zalogowano pomyślnie jako: ', user.userName);
      this.router.navigate(['home']);
    }).catch((error: HttpErrorResponse) => {
      if (error.status === 404) {
        this.toastService.showWarning('Niepoprawna nazwa użytkownika lub hasło!', 'Spróbuj ponownie.');
      } else if (error.status === 401 || error.status === 403) {
        this.toastService.showWarning('Przekroczono maksymalną ilość sesji użytkownika!',
          'Wyloguj się z innego urządzenia i spróbuj ponownie.');
      } else {
        throw error;
      }
    });
  }

  public signOut(): void {
    this.logout().then(() => {
      this.authService.invalidateUser();
      this.router.navigate(['login']);
    });
  }

  public sendContact(body: ContactInterface): Promise<void> {
    return this.httpClient.post<void>('/api/admin/contactRequest', body).toPromise();
  }

  private login(name: string, password: string): Promise<UserAuthInterface> {
    return this.httpClient.post<UserAuthInterface>('/api/login', {name, password}).toPromise();
  }

  private logout(): Promise<HttpResponseBase> {
    let params = new HttpParams();
    params = params.append('session-token', this.authService.token);
    return this.httpClient.get<HttpResponseBase>('/api/logout', {params}).toPromise();
  }

}
