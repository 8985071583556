import { Component, Input, OnInit } from '@angular/core';
import { cloneDeep } from 'lodash';
import { CalculatorsService } from '../../../../services/calculators.service';
import { CalculatorInterface } from '../../../calculators-list/interfaces/calculator.interface';
import {
  LumpSumFromHospitalPerspectiveBodyInterface, LumpSumFromHospitalPerspectiveResponseInterface,
} from '../../interfaces/custom-calculators.interface';

@Component({
  selector: 'lump-sum-from-hospital-perspective-calculator',
  templateUrl: './lump-sum-from-hospital-perspective-calculator.component.html',
  styleUrls: ['./lump-sum-from-hospital-perspective-calculator.component.scss'],
})
export class LumpSumFromHospitalPerspectiveCalculatorComponent implements OnInit {

  @Input() calculator: CalculatorInterface;
  public calculatorData: LumpSumFromHospitalPerspectiveBodyInterface = {
    qualification: {
      histologyProvisionsPerPatient: 1,
      histologyProvisionCost: 60,
      molecularProvisionsPerPatient: 1,
      molecularProvisionCost: 750,
      morphologyProvisionsPerPatient: 1,
      morphologyProvisionCost: 47,
      pregnancyProvisionsPerPatient: 1,
      pregnancyProvisionCost: 30,
      ekgProvisionsPerPatient: 1,
      ekgProvisionCost: 30,
      ctProvisionsPerPatient: 1,
      ctProvisionCost: 250,
      imagingProvisionsPerPatient: 1,
      imagingProvisionCost: 250,
    },
    safetyMonitoring: {
      morphologyProvisionsPerPatient: 12,
      morphologyProvisionCost: 60,
      tshProvisionsPerPatient: 0,
      tshProvisionCost: 47,
      ekgProvisionsPerPatient: 12,
      ekgProvisionCost: 30,
      phosphocreartinKinaseProvisionsPerPatient: 12,
      phosphocreartinKinaseProvisionCost: 15,
    },
    effectivenessMonitoring: {
      ctProvisionsPerPatient: 4,
      ctProvisionCost: 250,
      imagingProvisionsPerPatient: 0,
      imagingProvisionCost: 0,
    },
  };
  public result: LumpSumFromHospitalPerspectiveResponseInterface;
  public isDataLoading = true;

  constructor(private readonly service: CalculatorsService) {
  }

  async ngOnInit(): Promise<void> {
    await this.runCalculator();
  }

  public async onCalculatorChange(): Promise<void> {
    await this.runCalculator();
  }

  private async runCalculator(): Promise<void> {
    this.isDataLoading = true;
    const result = await this.service.runLumpSumFromHospitalPerspectiveCalculator(this.calculatorData);
    this.result = cloneDeep(result);
    this.isDataLoading = false;
  }

}
