import { Injectable } from '@angular/core';
import { UserAuthInterface } from '../../auth/auth.interface';

const APP_PREFIX = 'app-';
const TOKEN_KEY = 'TOKEN';
const USER_KEY = 'USER';

@Injectable()
export class LocalStorageService {

  public setItem(key: string, value: any): void {
    localStorage.setItem(`${APP_PREFIX}${key}`, JSON.stringify(value));
  }

  public getItem(key: string): any {
    return JSON.parse(localStorage.getItem(`${APP_PREFIX}${key}`));
  }

  public getToken(): string {
    return this.getItem(TOKEN_KEY);
  }

  public setToken(value: string): void {
    this.setItem(TOKEN_KEY, value);
  }

  public getUser(): UserAuthInterface {
    return this.getItem(USER_KEY);
  }

  public setUser(value: UserAuthInterface): void {
    this.setItem(USER_KEY, value);
  }

  public invalidateToken(): void {
    this.setItem(TOKEN_KEY, null);
  }

  public invalidateUser(): void {
    this.setItem(USER_KEY, null);
  }

  public isTokenValid(): boolean {
    return Boolean(this.getToken());
  }
}
