import { Injectable } from '@angular/core';
import { LocalStorageService } from '../shared/services/local-storage.service';
import { UserAuthInterface } from './auth.interface';

@Injectable()
export class AuthService {

  public get user(): UserAuthInterface {
    return this._user || this.localStorageService.getUser();
  }

  public set user(user: UserAuthInterface) {
    this._user = user;
    this.localStorageService.setUser(user);
  }

  public get token(): string {
    return this._token || this.localStorageService.getToken();
  }

  public set token(token: string) {
    this._token = token;
    this.localStorageService.setToken(token);
  }

  public get isAuthenticated(): boolean {
    return this.localStorageService.isTokenValid();
  }

  private _user: UserAuthInterface;
  private _token: string;

  constructor(private readonly localStorageService: LocalStorageService) {}

  public invalidateUser(): void {
    this.user = null;
    this.token = null;
    this.localStorageService.invalidateUser();
    this.localStorageService.invalidateToken();
  }
}
