import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {

  public userName = '';
  public userPassword = '';

  constructor(private readonly loginService: LoginService, private readonly router: Router) { }

  public signIn(): void {
    this.loginService.signIn(this.userName, this.userPassword);
    this.router.navigate(['home']);
  }

}
