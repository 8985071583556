import { Component, Input, OnInit } from '@angular/core';
import { cloneDeep } from 'lodash';
import { CalculatorsService } from '../../../../services/calculators.service';
import { CalculatorInterface } from '../../../calculators-list/interfaces/calculator.interface';
import {
  RefundLungCancerCalculatorBodyInterface, RefundLungCancerCalculatorDefaultsInterface,
  RefundLungCancerCalculatorItemInterface, RefundLungCancerCalculatorResponseInterface,
} from '../../interfaces/custom-calculators.interface';

@Component({
  selector: 'refund-lung-cancer-calculator',
  templateUrl: './refund-lung-cancer-calculator.component.html',
  styleUrls: ['./refund-lung-cancer-calculator.component.scss'],
})
export class RefundLungCancerCalculatorComponent implements OnInit {

  @Input() calculator: CalculatorInterface;
  public specimens: string[] = [];
  public calculatorData: RefundLungCancerCalculatorBodyInterface = {
    items: [],
  };
  public calculatorResult: RefundLungCancerCalculatorResponseInterface;
  public isDataLoading = true;
  private specimensList: string[] = [];

  constructor(private readonly service: CalculatorsService) {
  }

  async ngOnInit(): Promise<void> {
    await this.loadSpecimens();
    await this.prepareCalculatorDefaults();
  }

  public async calculatorDataChange(): Promise<void> {
    await this.loadCalculatorResult();
  }

  public async specimenChange(index: number, product: string): Promise<void> {
    this.specimens[index] = product;
    await this.prepareCalculatorDefaults();
  }

  private async loadSpecimens(): Promise<void> {
    this.specimens = await this.service.getSpecimens(this.calculator.id);
    this.specimensList = cloneDeep(this.specimens);
  }

  private async prepareCalculatorDefaults(): Promise<void> {
    const calculatorItems: RefundLungCancerCalculatorItemInterface[] = [];
    for (const specimen of this.specimens) {
      const defaults: RefundLungCancerCalculatorDefaultsInterface = await this.service.getDefaults(this.calculator.id, specimen);
      const item: RefundLungCancerCalculatorItemInterface = {
        product: specimen,
        qualification: {
          histologyConfirmation: 'Ryczałt za diagnostykę w programie leczenia niedrobnokomórkowego raka płuca',
          molecularConfirmation: 'SZP-Podstawowe badanie genetyczne w chorobach nowotworowych',
          morphology: 'Ryczałt za diagnostykę w programie leczenia niedrobnokomórkowego raka płuca',
          pregnancyTest: 'Ryczałt za diagnostykę w programie leczenia niedrobnokomórkowego raka płuca',
          ekg: 'Ryczałt za diagnostykę w programie leczenia niedrobnokomórkowego raka płuca',
          tomography: 'Ryczałt za diagnostykę w programie leczenia niedrobnokomórkowego raka płuca',
          otherImaging: 'Ryczałt za diagnostykę w programie leczenia niedrobnokomórkowego raka płuca',
        },
        administration: {
          administration: defaults.administrations,
          oneTimeHospitalization: defaults.oneTimeHospitalizations,
          programExecution: defaults.programExecutions,
        },
        drugs: {
          packagePrice: defaults.packagePrice,
          packageContentsMg: defaults.packageContentsMg,
          doseMg: defaults.doseMg,
          dosing: defaults.dosing,
          dosesPerYear: defaults.dosesPerYear,
        },
        safetyMonitoring: {
          morphology: defaults.monitoringMorphology,
          tsh: defaults.monitoringTsh,
          ekg: defaults.monitoringEkg,
          phosphocreartinKinaseCount: defaults.monitoringPhosphocreartinKinase,
        },
        effectivenessMonitoring: {
          tomography: defaults.effectivenessCT,
          otherImaging: defaults.effectivenessOtherImaging,
        },
        patients: defaults.patientCount,
      };
      calculatorItems.push(item);
    }
    this.calculatorData.items = cloneDeep(calculatorItems);
    await this.loadCalculatorResult();
  }

  private async loadCalculatorResult(): Promise<void> {
    this.isDataLoading = true;
    const result = await this.service.runRefundLungCancerCalculator(this.calculatorData);
    this.calculatorResult = cloneDeep(result);
    this.isDataLoading = false;
  }

}
