import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { ToastService } from '../shared/toast/toast.service';
import {
  HTTP_ERROR_MESSAGE,
  HTTP_ERROR_TITLE,
  INTERNAL_ERROR_MESSAGE,
  INTERNAL_ERROR_TITLE,
  NO_ACCESS_MESSAGE,
  NO_ACCESS_TITLE,
  NOT_LOGGED_IN_MESSAGE,
  NOT_LOGGED_IN_TITLE,
  SESSION_EXPIRED_MESSAGE,
  SESSION_EXPIRED_TITLE
} from './constants';

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  private get toastService(): ToastService {
    return this.injector.get(ToastService);
  }

  constructor(private injector: Injector, private ngZone: NgZone) {
  }

  public handleError(error: any): void {
    this.ngZone.run(() => {
      if (error.promise) {
        error.promise
          .then(data => {
            this.onHandle(data);
          })
          .catch(err => {
            this.onHandle(err);
          });
      } else {
        this.onHandle(error);
      }
    });
  }

  private onHandle(err: Error): void {
    if (err instanceof HttpErrorResponse) {
      this.handleServiceError(err);
    } else if (err instanceof TypeError) {
      throw err;
    } else {
      this.toastService.showError(INTERNAL_ERROR_TITLE, INTERNAL_ERROR_MESSAGE);
      console.error(err);
    }
  }

  private handleServiceError(err: HttpErrorResponse): void {
    console.error(err);
    switch (err.status) {
      case 401:
        this.toastService.showWarning(NOT_LOGGED_IN_TITLE, NOT_LOGGED_IN_MESSAGE);
        break;
      case 403:
        this.toastService.showWarning(NO_ACCESS_TITLE, NO_ACCESS_MESSAGE);
        break;
      case 440:
        this.toastService.showWarning(SESSION_EXPIRED_TITLE, SESSION_EXPIRED_MESSAGE);
        break
      default:
        this.toastService.showError(HTTP_ERROR_TITLE, HTTP_ERROR_MESSAGE);
    }
  }
}
