import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'test-charts',
  templateUrl: './test-charts.component.html',
  styleUrls: ['./test-charts.component.scss'],
})
export class TestChartsComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
