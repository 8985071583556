import { Model } from '../../../../shared/json-mapper/model';
import { FavoriteCalculatorInterface } from '../interfaces/favorite-calculator.interface';

export class FavoriteCalculatorModel extends Model<FavoriteCalculatorInterface> {

  get id(): string {
    return this.data && this.data.calculatorId;
  }

  get name(): string {
    return this.data && this.data.calculatorName;
  }

  get description(): string {
    return this.data && this.data.calculatorDescription;
  }

  get categoryId(): string {
    return this.data && this.data.categoryId;
  }

  get categoryName(): string {
    return this.data && this.data.categoryName;
  }

  constructor(json: FavoriteCalculatorInterface) {
    super(json);
  }
}
