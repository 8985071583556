import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';
import { LoginService } from '../login/login.service';
import { LocalStorageService } from '../shared/services/local-storage.service';
import { ToastService } from '../shared/toast/toast.service';

export const HTTP_UNAUTHORIZED_CODE = 401;
export const HTTP_FORBIDDEN_CODE = 403;
export const HTTP_SESSION_TIMEOUT_CODE = 440;

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private localStorageService: LocalStorageService,
    private loginService: LoginService,
    private toastService: ToastService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.localStorageService.getToken();
    let updatedRequest = request;
    if (token) {
      const authToken = token;
      updatedRequest = request.clone({
        setHeaders: {
          Authorization: `${authToken}`,
        },
      });
    }

    return next.handle(updatedRequest).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            return next.handle(updatedRequest);
          }
        },
        error => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === HTTP_FORBIDDEN_CODE) {
              this.toastService.showWarning('Uwaga!', 'Nie masz dostępu do wybranego elementu.');
            }
            if (error.status === HTTP_UNAUTHORIZED_CODE || error.status === HTTP_SESSION_TIMEOUT_CODE) {
              this.loginService.signOut();
            }
          }
        },
      ),
    );
  }
}
