import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldInterface } from '../interfaces/field.interface';

@Component({
  selector: 'dropdown',
  template: `
    <div [formGroup]="form">
      <select class="form-control" [id]="field.name" [formControlName]="field.name">
        <option [value]="''" disabled selected hidden>Select...</option>
        <option *ngFor="let opt of field.options" [value]="opt.key">{{opt.label}}</option>
      </select>
    </div>
  `,
})
export class DropDownComponent {
  @Input() field: FieldInterface;
  @Input() form: FormGroup;
}
