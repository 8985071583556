import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ChartType } from '../../charts/interfaces/chart-data.interface';
import { CalculatorResultInterface } from '../interfaces/calculator-result.interface';

@Component({
  selector: 'calculator-result',
  templateUrl: './calculator-result.component.html',
  styleUrls: ['./calculator-result.component.scss'],
})
export class CalculatorResultComponent implements OnChanges {
  @Input() calculatorResult: CalculatorResultInterface;
  @Output() goBack = new EventEmitter<boolean>();
  @Output() onExport = new EventEmitter<boolean>();

  public chartTypes = ChartType;
  public selectedTab: { name: string; index: number };

  ngOnChanges(changes: SimpleChanges): void {
    const resultChange = changes['calculatorResult'];
    if (resultChange && this.calculatorResult && this.calculatorResult.tabs && this.calculatorResult.tabs.length > 0) {
      this.selectedTab = {
        name: this.calculatorResult.tabs[0].title,
        index: 0,
      };
    }
  }

  public export(): void {
    this.onExport.emit(true);
  }

  public back(): void {
    this.goBack.emit(true);
  }

  public selectTab(name: string, index: number): void {
    this.selectedTab = {
      name,
      index,
    };
  }
}
