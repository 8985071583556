import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CalculatorsService } from '../../services/calculators.service';
import { CategoryModel } from './models/category.model';

@Component({
  selector: 'categories-list',
  templateUrl: './categories-list.component.html',
  styleUrls: ['./categories-list.component.scss'],
})
export class CategoriesListComponent implements OnInit {

  public form: FormGroup;
  public categories: CategoryModel[];
  public categoriesLoading = true;

  constructor(private service: CalculatorsService, private router: Router) {
  }

  ngOnInit(): void {
    this.loadCategories();
  }

  public onSelectCategory(categoryId: string): void {
    this.router.navigate(['home', 'categories', categoryId, 'calculators']);
  }

  private loadCategories(): void {
    this.service.getCategories().then(categories => {
      this.categories = categories;
      this.categoriesLoading = false;
    });
  }

}
