import { Model } from '../../../../shared/json-mapper/model';
import { CategoryInterface } from '../interfaces/category.interface';

export class CategoryModel extends Model<CategoryInterface> {

  get name(): string {
    return this.data && this.data.name;
  }

  get description(): string {
    return this.data && this.data.description;
  }

  constructor(json: CategoryInterface) {
    super(json);
  }
}
