import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { AfterViewInit, Component, Input, NgZone, OnDestroy } from '@angular/core';
import { CalculatorChartDataInterface } from '../../calculator/interfaces/calculator-result.interface';
import { ChartDataInterface } from '../interfaces/chart-data.interface';

am4core.useTheme(am4themes_animated);

@Component({
  selector: 'donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss'],
})
export class DonutChartComponent implements AfterViewInit, OnDestroy {

  @Input() title: string;
  @Input() chartId = 'donutChart';
  @Input() data: CalculatorChartDataInterface[];

  private chart: am4charts.PieChart;

  constructor(private zone: NgZone) {
  }

  ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      const chart = am4core.create(this.chartId, am4charts.PieChart);
      chart.data = this.prepareChartData(this.data);
      chart.innerRadius = am4core.percent(50);
      chart.paddingRight = 100;
      chart.paddingLeft = 100;

      const title = chart.titles.create();
      title.text = this.title;
      title.fontSize = 20;
      title.marginBottom = 10;
      title.marginTop = 10;
      title.align = 'center';

      const pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = 'value';
      pieSeries.dataFields.category = 'label';
      pieSeries.slices.template.stroke = am4core.color('#fff');
      pieSeries.slices.template.strokeWidth = 2;
      pieSeries.slices.template.strokeOpacity = 1;
      pieSeries.hiddenState.properties.opacity = 1;
      pieSeries.hiddenState.properties.endAngle = -90;
      pieSeries.hiddenState.properties.startAngle = -90;

      this.chart = chart;
    });
  }

  ngOnDestroy(): void {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

  private prepareChartData(data: CalculatorChartDataInterface[]): ChartDataInterface[] {
    return data.map(element => {
      return {
        label: element.item,
        value: element.values[0],
      };
    });
  }

}
