export const TYPE_ERROR_TITLE = 'TypeError occurred!';
export const TYPE_ERROR_MESSAGE = (message: string) => `message: ${message}`;
export const HTTP_ERROR_TITLE = 'Coś poszło nie tak!';
export const HTTP_ERROR_MESSAGE = 'Spróbuj ponownie później.';
export const INTERNAL_ERROR_TITLE = 'Coś poszło nie tak!';
export const INTERNAL_ERROR_MESSAGE = 'Skontaktuj się z administratorem systemu.';
export const SESSION_EXPIRED_TITLE = 'Zostałeś wylogowany';
export const SESSION_EXPIRED_MESSAGE = 'Sesja wygasła z powodu długiej nieaktywności.';
export const NOT_LOGGED_IN_TITLE = 'Zostałeś wylogowany';
export const NOT_LOGGED_IN_MESSAGE = 'Twoja sesja straciła ważność.';
export const NO_ACCESS_TITLE = 'Brak dostępu';
export const NO_ACCESS_MESSAGE = 'Nie masz przyznanego dostępu do wybranego zasobu.';

