import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SelectModule } from 'ng2-select';
import { ModalModule, TooltipModule } from 'ngx-bootstrap';
import { BreadcrumbsComponent } from '../shared/breadcrumbs/breadcrumbs.component';
import { DynamicFormBuilderModule } from '../shared/dynamic-form-builder/dynamic-form-builder.module';
import { LoaderComponent } from '../shared/loader/loader.component';
import { NoDataLabelComponent } from '../shared/no-data-label/no-data-label.component';
import { CalculatorFormComponent } from './components/calculator/calculator-form/calculator-form.component';
import { CalculatorResultComponent } from './components/calculator/calculator-result/calculator-result.component';
import { CalculatorWizardComponent } from './components/calculator/calculator-wizard/calculator-wizard.component';
import { CalculatorComponent } from './components/calculator/calculator.component';
import { LumpSumFromHospitalPerspectiveCalculatorComponent } from './components/calculator/custom-calculators/lump-sum-from-hospital-perspective-calculator/lump-sum-from-hospital-perspective-calculator.component';
import { RefundLungCancerCalculatorComponent } from './components/calculator/custom-calculators/refund-lung-cancer-calculator/refund-lung-cancer-calculator.component';
import { CalculatorsListComponent } from './components/calculators-list/calculators-list.component';
import { RecentCalculatorsListComponent } from './components/calculators-recent/recent-calculators-list.component';
import { FavoritesCalculatorsListComponent } from './components/calculcators-favorites/favorites-calculators-list.component';
import { CategoriesListComponent } from './components/categories-list/categories-list.component';
import { ChangePasswordModalComponent } from './components/change-password-modal/change-password-modal.component';
import { BarChartComponent } from './components/charts/bar-chart/bar-chart.component';
import { DonutChartComponent } from './components/charts/donut-chart/donut-chart.component';
import { HorizontalBarChartComponent } from './components/charts/horizontal-bar-chart/horizontal-bar-chart.component';
import { HorizontalStackedBarChartComponent } from './components/charts/horizontal-stacked-bar-chart/horizontal-stacked-bar-chart.component';
import { LineChartComponent } from './components/charts/line-chart/line-chart.component';
import { PieChartComponent } from './components/charts/pie-chart/pie-chart.component';
import { StackedBarChartComponent } from './components/charts/stacked-bar-chart/stacked-bar-chart.component';
import { StackedLineChartComponent } from './components/charts/stacked-line-chart/stacked-line-chart.component';
import { TestChartsComponent } from './components/charts/test-charts.component';
import { ContactComponent } from './components/contact/contact.component';
import { HomeComponent } from './components/home.component';
import { CalculatorsService } from './services/calculators.service';

const COMPONENTS = [
  HomeComponent,
  CalculatorComponent,
  CategoriesListComponent,
  CalculatorResultComponent,
  FavoritesCalculatorsListComponent,
  LoaderComponent,
  NoDataLabelComponent,
  BreadcrumbsComponent,
  ContactComponent,
  ChangePasswordModalComponent,
  RefundLungCancerCalculatorComponent,
  LumpSumFromHospitalPerspectiveCalculatorComponent,
];

const PUBLIC_COMPONENTS = [];

const PIPES = [];

const PROVIDERS = [CalculatorsService];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule,
    DynamicFormBuilderModule,
    ModalModule.forRoot(),
    SelectModule,
    TooltipModule.forRoot(),
  ],
  declarations: [
    ...COMPONENTS,
    ...PUBLIC_COMPONENTS,
    ...PIPES,
    CalculatorsListComponent,
    RecentCalculatorsListComponent,
    CalculatorResultComponent,
    CalculatorFormComponent,
    LineChartComponent,
    PieChartComponent,
    DonutChartComponent,
    BarChartComponent,
    StackedBarChartComponent,
    HorizontalBarChartComponent,
    HorizontalStackedBarChartComponent,
    TestChartsComponent,
    HorizontalBarChartComponent,
    HorizontalStackedBarChartComponent,
    PieChartComponent,
    StackedBarChartComponent,
    StackedLineChartComponent,
    CalculatorWizardComponent,
  ],
  exports: [
    ...PUBLIC_COMPONENTS,
    ...PIPES,
    ChangePasswordModalComponent,
  ],
})
export class CalculatorsModule {

  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: CalculatorsModule,
      providers: [
        ...PROVIDERS,
      ],
    };
  }

}
