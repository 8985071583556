import { Model } from '../../../../shared/json-mapper/model';
import { CalculatorInterface } from '../interfaces/calculator.interface';

export class CalculatorModel extends Model<CalculatorInterface> {

  get name(): string {
    return this.data && this.data.name;
  }

  get description(): string {
    return this.data && this.data.description;
  }

  get favorite(): boolean {
    return this.data && this.data.favorite;
  }

  get categoryId(): string {
    return this.data && this.data.categoryId;
  }

  constructor(json: CalculatorInterface) {
    super(json);
  }
}
