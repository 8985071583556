import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CalculatorsService } from '../../services/calculators.service';
import { FavoriteCalculatorModel } from './models/favorite-calculator.model';

@Component({
  selector: 'calculators-favorites',
  templateUrl: './favorites-calculators-list.component.html',
  styleUrls: ['./favorites-calculators-list.component.scss'],
})
export class FavoritesCalculatorsListComponent implements OnInit {
  public calculators: FavoriteCalculatorModel[];
  public calculatorsLoading = true;

  constructor(private readonly service: CalculatorsService,
              private readonly router: Router) {
  }

  async ngOnInit(): Promise<void> {
    await this.loadCalculators();
  }

  public onSelectCalculator(categoryId: string, calculatorId: string): void {
    this.router.navigate(['home', 'categories', categoryId, 'calculators', calculatorId]);
  }

  public remove(calculatorId: string): void {
    this.service.unMarkFavorite(calculatorId).then(async () => {
      this.loadCalculators();
    });
  }

  private async loadCalculators(): Promise<void> {
    this.calculatorsLoading = true;
    this.calculators = await this.service.getFavoritesCalculators();
    this.calculatorsLoading = false;
  }
}
