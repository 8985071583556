import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { AfterViewInit, Component, Input, NgZone, OnDestroy } from '@angular/core';
import { CalculatorChartDataInterface } from '../../calculator/interfaces/calculator-result.interface';

am4core.useTheme(am4themes_animated);

@Component({
  selector: 'bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements AfterViewInit, OnDestroy {

  @Input() title: string;
  @Input() chartId = 'barChart';
  @Input() data: CalculatorChartDataInterface[];
  @Input() series: string[];

  private chart: am4charts.XYChart;

  constructor(private readonly zone: NgZone) {
  }

  ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      const chart = am4core.create(this.chartId, am4charts.XYChart);
      chart.data = this.prepareChartData(this.data, this.series);

      const title = chart.titles.create();
      title.text = this.title;
      title.fontSize = 20;
      title.marginBottom = 10;
      title.marginTop = 10;
      title.align = 'center';

      const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = 'seriesName';
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;
      categoryAxis.renderer.labels.template.rotation = this.getSeriesLabelRotation();
      categoryAxis.renderer.labels.template.verticalCenter = 'middle';
      categoryAxis.renderer.cellStartLocation = 0.1;
      categoryAxis.renderer.cellEndLocation = 0.9;

      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      if (!this.hasNegativeValues()) {
        valueAxis.min = 0;
      }

      const createSeries = (name: string): am4charts.ColumnSeries => {
        const series = chart.series.push(new am4charts.ColumnSeries());
        series.name = name;
        series.dataFields.valueY = name;
        series.dataFields.categoryX = 'seriesName';
        series.columns.template.tooltipText = '[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}';
        // series.columns.template.fill = am4core.color('#282753');
        // series.columns.template.stroke = am4core.color('#282753');

        return series;
      };

      this.series.forEach(seriesName => {
        createSeries(seriesName);
      });

      this.chart = chart;
    });
  }

  ngOnDestroy(): void {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

  private prepareChartData(data: CalculatorChartDataInterface[], series: string[]): Array<{ [key: string]: string | number }> {
    return data.map((element) => {
      const result = { seriesName: element.item };
      series.forEach((seriesName, index) => {
        result[seriesName] = element.values[index];
      });
      return result;
    });
  }

  private getSeriesLabelRotation(): number {
    return this.data.length > 3 ? 270 : 0;
  }

  private hasNegativeValues(): boolean {
    let hasNegative = false;
    this.data.forEach(element => {
      hasNegative = element.values.some(item => item < 0);
    });
    return hasNegative;
  }

}
