import { Component, Input } from '@angular/core';
import { BreacrumbsItem } from './breadcrumbs-item.model';

@Component({
  selector: 'breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent {
  @Input() items: BreacrumbsItem[] = [];

}
