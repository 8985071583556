import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldInterface } from '../interfaces/field.interface';

@Component({
  selector: 'checkbox',
  template: `
    <div [formGroup]="form">
      <div [formGroupName]="field.name">
        <div *ngFor="let opt of field.options" class="form-check form-check">
          <label class="form-check-label">
            <input [formControlName]="opt.key" class="form-check-input" type="checkbox"/>
            <span>{{opt.label}}</span>
          </label>
        </div>
      </div>
    </div>
  `,
})
export class CheckBoxComponent {
  @Input() field: FieldInterface;
  @Input() form: FormGroup;
}
