import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastService } from '../../../shared/toast/toast.service';
import { LoginService } from '../../login.service';

@Component({
  selector: 'contact-modal',
  templateUrl: './contact-modal.component.html',
  styleUrls: ['./contact-modal.component.scss'],
})
export class ContactModalComponent implements OnInit {
  @ViewChild('contactModal') modal: ModalDirective;
  public showModalBody = false;
  public contactForm: FormGroup;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly toastService: ToastService,
    private readonly loginService: LoginService,
  ) {
  }

  ngOnInit(): void {
    this.createFormGroup();
  }

  public show(): void {
    this.showModalBody = true;
    this.modal.show();
  }

  public hide(): void {
    this.showModalBody = false;
    this.createFormGroup();
    this.modal.hide();
  }

  public save(): void {
    this.loginService.sendContact(this.contactForm.value)
      .then(() => {
        this.toastService.showSuccess('Wysłano prośbę o udzielenie dostępu.');
        this.hide();
      }).catch(() => {
      this.toastService.showError('Wprowadzone dane są niepoprawne.', 'Spróbuj ponownie.');
    });
    this.hide();
  }

  private createFormGroup(): void {
    this.contactForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(1)]],
      company: ['', [Validators.required, Validators.minLength(1)]],
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
      phone: ['', [Validators.required, Validators.minLength(1)]],
      dataProcessingConsent: [false, Validators.pattern('true')],
      recaptcha: [null, Validators.required],
    });
  }
}
