import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { AfterViewInit, Component, Input, NgZone, OnDestroy } from '@angular/core';
import { CalculatorChartDataInterface } from '../../calculator/interfaces/calculator-result.interface';

am4core.useTheme(am4themes_animated);

@Component({
  selector: 'horizontal-stacked-bar-chart',
  templateUrl: './horizontal-stacked-bar-chart.component.html',
  styleUrls: ['./horizontal-stacked-bar-chart.component.scss'],
})
export class HorizontalStackedBarChartComponent implements AfterViewInit, OnDestroy {

  @Input() title: string;
  @Input() chartId = 'horizontalStackedBarChart';
  @Input() data: CalculatorChartDataInterface[];
  @Input() series: string[];

  private chart: am4charts.XYChart;

  constructor(private zone: NgZone) {
  }

  ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      const chart = am4core.create(this.chartId, am4charts.XYChart);
      chart.paddingRight = 100;
      chart.data = this.prepareChartData(this.data, this.series);

      const title = chart.titles.create();
      title.text = this.title;
      title.fontSize = 20;
      title.marginBottom = 10;
      title.marginTop = 10;
      title.align = 'center';

      const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = 'seriesName';
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;
      categoryAxis.renderer.inversed = true;

      const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.opposite = true;
      valueAxis.min = 0;

      const createSeries = (name: string): am4charts.ColumnSeries => {
        const series = chart.series.push(new am4charts.ColumnSeries());
        series.name = name;
        series.dataFields.valueX = name;
        series.dataFields.categoryY = 'seriesName';
        series.columns.template.tooltipText = '[bold]{name}[/]\n[font-size:14px]{categoryY}: {valueX}';
        series.sequencedInterpolation = true;
        series.stacked = true;
        // series.columns.template.fill = am4core.color('#282753');
        // series.columns.template.stroke = am4core.color('#282753');

        return series;
      };

      this.series.forEach(seriesName => {
        createSeries(seriesName);
      });

      this.chart = chart;
    });
  }

  ngOnDestroy(): void {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

  private prepareChartData(data: CalculatorChartDataInterface[], series: string[]): Array<{ [key: string]: string | number }> {
    return data.map((element) => {
      const result = { seriesName: element.item };
      series.forEach((seriesName, index) => {
        result[seriesName] = element.values[index];
      });
      return result;
    });
  }
}
