import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldInterface } from '../interfaces/field.interface';

@Component({
  selector: 'field-builder',
  template: `
    <div class="form-group row" [formGroup]="form">
      <label class="col-md-3 form-control-label" [attr.for]="field.label">
        {{field.label}}
        <strong class="text-danger" *ngIf="field.required">*</strong>
        <sup *ngIf="field.tooltip" class="ml-1">
          <i [tooltip]="field.tooltip" class="fas fa-info-circle"></i>
        </sup>
      </label>
      <div class="col-md-9" [ngSwitch]="field.type">
        <textbox *ngSwitchCase="'text'" [field]="field" [form]="form"></textbox>
        <dropdown *ngSwitchCase="'dropdown'" [field]="field" [form]="form"></dropdown>
        <checkbox *ngSwitchCase="'checkbox'" [field]="field" [form]="form"></checkbox>
        <number *ngSwitchCase="'number'" [field]="field" [form]="form"></number>
        <div class="invalid text-uppercase" *ngIf="!isValid">
          Pole nie spełnia warunków walidacji
        </div>
      </div>
    </div>
  `,
  styles: [`
    .invalid {
      font-size: 12px;
      color: #A31919;
      margin-top: 2px;
    }
  `],
})
export class FieldBuilderComponent {

  @Input() field: FieldInterface;
  @Input() form: FormGroup;

  get isValid(): boolean {
    return this.form.controls[this.field.name].valid;
  }

  get isDirty(): boolean {
    return this.form.controls[this.field.name].dirty;
  }
}
