import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from './guards/authentication.guard';
import { LoggedInGuard } from './guards/logged-in.guard';
import { LoginComponent } from './login/components/login.component';
import { CalculatorComponent } from './views/components/calculator/calculator.component';
import { CalculatorsListComponent } from './views/components/calculators-list/calculators-list.component';
import { RecentCalculatorsListComponent } from './views/components/calculators-recent/recent-calculators-list.component';
import { FavoritesCalculatorsListComponent } from './views/components/calculcators-favorites/favorites-calculators-list.component';
import { CategoriesListComponent } from './views/components/categories-list/categories-list.component';
import { ContactComponent } from './views/components/contact/contact.component';
import { HomeComponent } from './views/components/home.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'home/contact',
    component: ContactComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'home/categories',
    component: CategoriesListComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'home/categories/:categoryId/calculators',
    component: CalculatorsListComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'home/recent',
    component: RecentCalculatorsListComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'home/favorites',
    component: FavoritesCalculatorsListComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'home/categories/:categoryId/calculators/:calculatorId',
    component: CalculatorComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [LoggedInGuard, AuthenticationGuard],
})
export class AppRoutingModule {
}
