import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

@Injectable()
export class ToastService {
  constructor(private toast: ToastrService) {}

  public showSuccess(title?: string, message?: string, options?: IndividualConfig): void {
    this.toast.success(message, title, options);
  }

  public showWarning(title?: string, message?: string, options?: IndividualConfig): void {
    this.toast.warning(message, title, options);
  }

  public showError(title?: string, message?: string, options?: IndividualConfig): void {
    this.toast.error(message, title, options);
  }
}
