import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { AfterViewInit, Component, Input, NgZone, OnDestroy } from '@angular/core';
import { CalculatorChartDataInterface } from '../../calculator/interfaces/calculator-result.interface';

am4core.useTheme(am4themes_animated);

@Component({
  selector: 'line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements AfterViewInit, OnDestroy {

  @Input() title: string;
  @Input() chartId = 'lineChart';
  @Input() data: CalculatorChartDataInterface[];
  @Input() series: string[];

  private chart: am4charts.XYChart;

  constructor(private zone: NgZone) {
  }

  ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      const chart = am4core.create(this.chartId, am4charts.XYChart);
      chart.data = this.prepareChartData(this.data, this.series);

      const title = chart.titles.create();
      title.text = this.title;
      title.fontSize = 20;
      title.marginBottom = 10;
      title.marginTop = 10;
      title.align = 'center';

      const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = 'seriesName';
      categoryAxis.renderer.labels.template.rotation = 270;
      categoryAxis.renderer.labels.template.horizontalCenter = 'right';
      categoryAxis.renderer.labels.template.verticalCenter = 'middle';
      categoryAxis.renderer.grid.template.disabled = false;
      categoryAxis.renderer.minGridDistance = 20;
      const yAxis = chart.yAxes.push(new am4charts.ValueAxis());
      yAxis.min = 0;

      const createSeries = (name: string): am4charts.LineSeries => {
        const series = chart.series.push(new am4charts.LineSeries());
        series.name = name;
        series.dataFields.categoryX = 'seriesName';
        series.dataFields.valueY = name;
        series.tooltipText = '{name}: [bold]{valueY}[/]';
        series.tensionX = 0.9;
        series.yAxis = yAxis;
        const interfaceColors = new am4core.InterfaceColorSet();
        const bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.stroke = interfaceColors.getFor('background');
        bullet.circle.strokeWidth = 1;

        return series;
      };

      this.series.forEach(element => {
        createSeries(element);
      });

      chart.cursor = new am4charts.XYCursor();
      chart.cursor.lineY.disabled = true;
      chart.cursor.lineX.disabled = true;
      chart.cursor.behavior = 'none';
      chart.legend = new am4charts.Legend();
      chart.legend.position = 'top';

      this.chart = chart;
    });
  }

  ngOnDestroy(): void {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

  private prepareChartData(data: CalculatorChartDataInterface[], series: string[]): Array<{ [key: string]: string | number }> {
    return data.map((element) => {
      const result = { seriesName: element.item };
      series.forEach((seriesName, index) => {
        result[seriesName] = element.values[index];
      });
      return result;
    });
  }

}
