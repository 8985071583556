export interface ChartDataInterface {
  label: string;
  value: number;
}

export interface StackedChartDataInterface extends ChartDataInterface {
  seriesName: string;
}

export enum ChartType {
  LINE = 'LINE',
  LINE_STACKED = 'LINE_STACKED',
  PIE = 'PIE',
  DONUT = 'DONUT',
  BAR = 'BAR',
  STACKED_BAR = 'STACKED_BAR',
  HORIZONTAL_BAR = 'HORIZONTAL_BAR',
  HORIZONTAL_STACKED = 'HORIZONTAL_STACKED',
}
