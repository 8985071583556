import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FieldInterface } from '../../../../shared/dynamic-form-builder/interfaces/field.interface';
import { CalculatorResultInterface } from '../interfaces/calculator-result.interface';
import { CalculatorFormDataInterface } from '../interfaces/calculator.interface';

@Component({
  selector: 'calculator-wizard',
  templateUrl: './calculator-wizard.component.html',
  styleUrls: ['./calculator-wizard.component.scss'],
})
export class CalculatorWizardComponent {

  @Input() calculatorName: string;
  @Input() hasNext = false;
  @Input() stepIndex = 0;
  @Input() fields: FieldInterface[];
  @Output() onCalculatorFormResult: EventEmitter<CalculatorFormDataInterface> = new EventEmitter<CalculatorFormDataInterface>();
  @Output() onSubmit: EventEmitter<CalculatorResultInterface> = new EventEmitter<CalculatorResultInterface>();
  @Output() onPreviousStep: EventEmitter<boolean> = new EventEmitter<boolean>();

  public submitForm(event: CalculatorFormDataInterface): void {
    this.onCalculatorFormResult.emit(event);
  }

  public handlePreviousStep(handle: boolean): void {
    this.onPreviousStep.emit(handle);
  }

}
