import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldInterface } from '../interfaces/field.interface';

@Component({
  selector: 'textbox',
  template: `
    <div [formGroup]="form">
      <input *ngIf="!field?.multiline" [attr.type]="field.type" class="form-control" [id]="field.name"
             [name]="field.name" [formControlName]="field.name">
      <textarea *ngIf="field?.multiline" [class.is-invalid]="isDirty && !isValid" [formControlName]="field.name"
                [id]="field.name"
                rows="9" class="form-control" [placeholder]="field.placeholder"></textarea>
    </div>
  `,
})
export class TextBoxComponent {
  @Input() field: FieldInterface;
  @Input() form: FormGroup;

  get isValid(): boolean {
    return this.form.controls[this.field.name].valid;
  }

  get isDirty(): boolean {
    return this.form.controls[this.field.name].dirty;
  }
}
