import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})

export class HomeComponent {
  constructor(private router: Router) {
  }

  public navigateToCategoriesList(): void {
    this.router.navigate(['home', 'categories']);
  }

  public navigateToRecentlyUsed(): void {
    this.router.navigate(['home', 'recent']);
  }

  public navigateToFavorites(): void {
    this.router.navigate(['home', 'favorites']);
  }

  public navigateToContacts(): void {
    this.router.navigate(['home', 'contact']);
  }
}
