import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserAuthInterface } from './auth/auth.interface';
import { AuthService } from './auth/auth.service';
import { LoginService } from './login/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  public get isSignedIn(): boolean {
    return this.authService.isAuthenticated;
  }

  public get user(): UserAuthInterface {
    return this.authService.user;
  }

  constructor(
    public translate: TranslateService,
    public router: Router,
    private authService: AuthService,
    private loginService: LoginService,
    private location: Location,
  ) {
    this.translate.setDefaultLang('pl');
    this.translate.use('pl');
  }

  public signOut(): void {
    this.loginService.signOut();
    this.router.navigate(['login']);
  }

  public goBack(): void {
    this.location.back();
  }
}
