import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastService } from '../../../shared/toast/toast.service';
import { CalculatorsService } from '../../services/calculators.service';

@Component({
  selector: 'change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss'],
})
export class ChangePasswordModalComponent implements OnInit {
  @ViewChild('changePasswordModal') modal: ModalDirective;
  public showModalBody = false;
  public changePasswordForm: FormGroup;

  public get isFormValid(): boolean {
    return this.changePasswordForm.valid
      && (this.changePasswordForm.get('newPassword').value === this.changePasswordForm.get('confirmedPassword').value);
  }

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly calculatorsService: CalculatorsService,
    private readonly toastService: ToastService,
    ) {
  }

  ngOnInit(): void {
    this.createFormGroup();
  }

  public show(): void {
    this.showModalBody = true;
    this.modal.show();
  }

  public hide(): void {
    this.showModalBody = false;
    this.createFormGroup();
    this.modal.hide();
  }

  public save(): void {
    const oldPassword = this.changePasswordForm.get('oldPassword').value;
    const newPassword = this.changePasswordForm.get('newPassword').value;
    this.calculatorsService.changePassword({
      oldPassword,
      newPassword,
    })
      .then(() => {
        this.toastService.showSuccess('Hasło zostało zmienione pomyślnie.');
        this.hide();
      }).catch(() => {
        this.toastService.showError('Wprowadzone dane są niepoprawne.', 'Spróbuj ponownie.');
    });
  }

  private createFormGroup(): void {
    this.changePasswordForm = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmedPassword: ['', Validators.required],
    });
  }
}
